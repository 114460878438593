<template>
  <div class="app-item app-content">
    <div class="top-title">
      <h4>部门管理</h4>
      <el-button type="primary" size="small" @click="openMoal()">添加部门</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="name" label="部门名称" fixed>
        <template slot-scope="scope">
          <el-input size="small" v-model="scope.row.name" placeholder="请输入内容 " v-if="showEdit[scope.$index]"></el-input>
          <span v-if="!showEdit[scope.$index]">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="部门负责人">
        <template slot-scope="scope">
          <el-input size="small" v-model="scope.row.principle" placeholder="请输入内容 "
            v-if="showEdit[scope.$index]"></el-input>
          <span v-if="!showEdit[scope.$index]">{{
            showPrincePle(scope.row)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="showEdit[scope.$index]" type="text" size="small"
            @click="updateDepartments(scope.$index, scope.row)">
            <span>保存</span>
          </el-button>
          <el-button v-if="!showEdit[scope.$index]" type="text" size="small"
            @click="updateDepartments(scope.$index, scope.row)">
            <span>编辑</span>
          </el-button>
          <!-- <el-button type="text" size="small">停用/启用</el-button> -->
          <el-button type="text" size="small" @click="deleteDepartments(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加部门 -->
    <el-dialog title="添加部门" :visible.sync="dialogFormVisible" class="dialog" width="600px">
      <el-form v-if="dialogFormVisible" label-width="100px" :rules="rules" :model="ruleForm" ref="ruleFormBox">
        <el-form-item label="部门名称" prop="deptname">
          <el-input auto-complete="off" placeholder="请输入部门名称" v-model="ruleForm.deptname" class="widthBFB"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人">
          <el-select v-model="ruleForm.principle" placeholder="请选择部门负责人" class="widthBFB" clearable filterable remote
            :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="list in deptList" :key="list.value" :value="list.value" :label="list.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级部门">
          <DeptVue ref="deptBox" @setData="setParent" :item="ruleForm.parent_id_arr" :multiple="false" />
          <!-- <el-cascader v-model="ruleForm.parent_id" :props="cascsderProps" :options="tableData" clearable class="widthBFB"
            :show-all-levels="false"></el-cascader> -->
          <!-- <el-select v-model="ruleForm.parent_id" placeholder="请选择上级部门" class="widthBFB" clearable>
            <el-option v-for="list in departmentList" :key="list.value" :value="list.value"
              :label="list.label"></el-option>
          </el-select> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addDepartments()">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import DeptVue from "./components/Dept";
import deptMixins from "./mixins";
export default {
  mixins: [deptMixins],
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      ruleForm: {
        deptname: "",
        principle: "",
        parent_id: ""
      },
      tableData: [],
      showEdit: [], // 显示编辑框
      showBtn: [],
      deptList: [],
      rules: {
        deptname: [
          { required: true, message: "请输入部门名称", trigger: "blur" }
        ]
      }
    };
  },
  components: {
    DeptVue
  },
  methods: {
    setParent(data) {
      this.ruleForm.parent_id = data;
    },
    remoteMethod(q) {
      if (q != "") {
        this.loading = true;
        this.getDepartments(`/api/web/store/users?q=${q}`);
      }
    },
    showPrincePle(row) {
      return row.principle_user ? row.principle_user.name : "--";
    },
    openMoal() {
      this.ruleForm = {
        deptname: "",
        principle: "",
        parent_id: [],
        parent_id_arr: []
      };
      this.dialogFormVisible = true;
    },
    showData(index, obj) {
      console.log(index, obj);
      this.showEdit[index] = !this.showEdit[index];
    },
    saveData(index, obj) {
      console.log(index, obj);
    },
    // 获取部门列表
    getDepartments(url = "") {
      //获取员工列表
      this.$store.dispatch("getEmployees", url).then(res => {
        if (res) {
          this.deptList = res.data.map(list => {
            return {
              value: list.user_id,
              label: list.name
            };
          });
          this.loading = false;
        }
      });
      //获取部门列表
      this.getDepartList();
    },
    // 增加部门信息
    addDepartments() {
      let res;
      let parent_id = 0;
      let len = this.ruleForm.parent_id.length;
      if (len) {
        if (len >= 4) {
          this.$notify.error({
            title: "错误",
            message: "不能在此部门下再建部门,请重试！"
          });
          return false;
        }
        parent_id = this.ruleForm.parent_id[len - 1];
      }
      let obj = {
        name: this.ruleForm.deptname,
        principle: this.ruleForm.principle || 0,
        parent_id: parent_id,
        parent_id_arr: this.ruleForm.parent_id || []
      };
      this.$refs["ruleFormBox"].validate(async valid => {
        if (valid) {
          if (this.ruleForm.id) {
            obj.id = this.ruleForm.id;
            res = await this.$store.dispatch("updateDepartments", obj);
            // .then(res => {
            //   if (res.code === 0) {
            //     this.$message({
            //       message: '修改成功',
            //       type: 'success'
            //     })
            //   }
            // })
          } else {
            res = await this.$store.dispatch("addDepartments", obj);
            // .then(res => {
            //   if (res.code === 0) {
            //     this.getDepartments()
            //     this.$message({
            //       message: '添加成功',
            //       type: 'success'
            //     });
            //     this.dialogFormVisible = false;
            //   }
            // })
          }

          if (res && res.code === 0) {
            this.getDepartments();
            this.dialogFormVisible = false;
            this.$message({
              message: this.ruleForm.id ? "添加成功" : "修改成功",
              type: "success"
            });
          }
        }
      });
    },
    // 删除部门信息
    deleteDepartments(index, row) {
      let id = row.id;
      this.$store.dispatch("deleteDepartments", id).then(res => {
        if (res.code === 0) {
          this.getDepartments();
          this.$message({
            message: "删除成功",
            type: "success"
          });
        }
      });
    },
    // 修改部门信息
    updateDepartments(index, row) {
      console.log(row, "row");
      // this.showEdit[index] = !this.showEdit[index]
      // this.showBtn[index] = !this.showBtn[index]
      this.ruleForm = {
        id: row.id,
        deptname: row.name,
        principle: row.principle,
        parent_id: [row.parent_id],
        parent_id_arr: row.parent_id_arr
      };

      //principle 部门负责人列表搜索到当前行选中的
      let name_ = row?.principle_user?.name || '';
      if (name_) {
        this.remoteMethod(name_);
      } else {
        this.getDepartments()
      }
      this.dialogFormVisible = true;

      // if (!this.showEdit[index]) {
      //   this.$store.dispatch('updateDepartments', {
      //     id: row.id,
      //     name: row.name,
      //     principle: row.principle || 0,
      //     parent_id: row.parent_id || 0
      //   }).then(res => {
      //     if (res.code === 0) {
      //       this.$message({
      //         message: '修改成功',
      //         type: 'success'
      //       })
      //     }
      //   })
      // }
    }
  },
  mounted() {
    this.getDepartments();
  }
};
</script>
<style lang="less" scoped>
.app-item {
  height: 100%;

  .widthBFB {
    width: 100%;
  }

  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog {
    text-align: center;

    .dialog-footer {
      text-align: center;
      margin-top: -10px;

      .el-button {
        padding: 12px 30px;
      }
    }
  }
}
</style>
